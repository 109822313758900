import { Component, Input } from "@angular/core";

@Component({
	selector: "f-stylistic",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class StylisticComponent {
	@Input() after: string;
	@Input() before: string;
}
