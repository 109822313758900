<f-microsite site="good" color="#6a0966"
	video="https://player.vimeo.com/external/391592617.m3u8?s=128d00a593f67e88594bb1db244d025f07709fa3"
	videoPoster="/assets/images/video/good-poster.jpg">
	<ng-container headline>From fundraising communications to online giving, Fathom helps nonprofits
		make the world a better place.</ng-container>
	<ng-container asterisk>From capital campaigns to annual giving, Fathom can help your nonprofit
		communicate its mission and motivate its supporters.</ng-container>

	<ng-container about>
		Fathom captures the authentic value of your nonprofit and plants seeds of
		generosity in the minds of your supporters.
	</ng-container>

	<ng-container about-identity>
		The right logo for your nonprofit or fundraising initiative captures your values,
		builds excitement, and invites people to participate.
	</ng-container>

	<ng-container about-design>
		From printed materials (or interactive pdfs) to websites, presentations,
		infographics, and more, great design differentiates your nonprofit and drives
		action.
	</ng-container>

	<ng-container about-development>
		Fathom’s web design solutions motivate donors. We can even follow through with
		integrated online giving solutions.
	</ng-container>

	<ng-container about-video>
		An explainer video can capture the hearts and minds of your audience. Or go
		further with a short documentary. We film and animate, but most of all, we
		motivate.
	</ng-container>
</f-microsite>
