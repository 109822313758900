import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { Platform } from "src/app/platform.service";

@Component({
	selector: "f-fadein",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	host: {
		"[class.show]": "show"
	}
})
export class FadeinComponent implements OnInit, OnDestroy {
	constructor(public platform: Platform, private elementRef: ElementRef) {}

	ngOnInit(): void {
		if (!this.platform.IS_BROWSER) return;
		this.observer = new IntersectionObserver(
			(entries, observer) => {
				if (entries[0].isIntersecting) {
					this.show = true;
					observer.unobserve(this.elementRef.nativeElement);
				}
			},
			{
				rootMargin: "0px 0px -10% 0px",
				threshold: 0
			}
		);
		this.observer.observe(this.elementRef.nativeElement);
	}

	ngOnDestroy(): void {
		if (!this.platform.IS_BROWSER) return;
		this.observer.unobserve(this.elementRef.nativeElement);
	}

	private show = false;
	private observer: IntersectionObserver;
}
