import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	Input,
	NgZone,
	ElementRef,
	ViewChild
} from "@angular/core";
import { Platform } from "src/app/platform.service";
import { ViewportScroller } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
	trigger,
	style,
	animate,
	transition,
	keyframes,
	query,
	stagger,
	group,
	AnimationBuilder
} from "@angular/animations";
import { Router } from "@angular/router";
import videojs from "video.js";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "f-microsite",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	host: {
		"(@startAnimation.done)": "animationDone = true",
		"(@startAnimation.start)": "hideEverything = false",
		"[@startAnimation]": "true",
		"[@.disabled]": "scrolled || !desktop",
		"[class.hide-everything]": "hideEverything",
		"[class.scroll-down]": "scrollDown",
		"[class.scroll-top]": "!scrollDown",
		"[style.background-color]": "color"
	},
	animations: [
		trigger("startAnimation", [
			transition(":enter", [
				query(
					".content>nav, .arrowContainer, #top .headline",
					style({ opacity: 0 })
				),
				query(".logo", [
					style({
						position: "relative",
						top: "calc(50vh - 46px)",
						left: "50%",
						transform: "translate(-50%, -50%) scale(5)"
					}),
					query(".letter", style({ transform: "translateY(100%)" })),
					query(
						".f",
						animate(
							"100ms 200ms ease",
							style({ transform: "translateY(0)" })
						)
					),
					query(
						".afterF .letter",
						stagger(
							100,
							animate(
								"100ms 400ms ease",
								style({ transform: "translateY(0)" })
							)
						)
					),
					animate(
						"500ms 400ms ease",
						style({
							top: 0,
							left: 0,
							transform: "translate(0, 0) scale(1)"
						})
					)
				]),
				query(
					"#top .headline",
					animate("500ms ease", style({ opacity: 1 }))
				),
				query(
					".content>nav, .arrowContainer",
					animate("500ms ease", style({ opacity: 1 }))
				)
			])
		])
	]
})
export class MicrositeComponent implements OnInit, OnDestroy, AfterViewInit {
	Math = Math;
	window = this.platform.IS_BROWSER ? window : null;

	constructor(
		public platform: Platform,
		private viewportScroller: ViewportScroller,
		private ngZone: NgZone,
		private animationBuilder: AnimationBuilder,
		private router: Router,
		public sanitizer: DomSanitizer,
		private http: HttpClient
	) {}

	// ==== parameters ====

	@Input() video: string;
	@Input() videoPoster: string;
	@Input() site: "business" | "good" | "agencies";
	@Input() color: string;

	// ==== lifecycle ====

	ngOnInit(): void {
		this.clientsDisplayOrder = (array => {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[array[i], array[j]] = [array[j], array[i]];
			}
			return array;
		})([...Array(this.clients.length).keys()]);
		if (this.platform.IS_BROWSER) {
			window.addEventListener("resize", () => this.resizeWindow());
			this.fragmentInterval = setInterval(() => {
				const pos = window.pageYOffset;
				const height = window.innerHeight;
				const sections = ["about", "work", "clients", "contact", "top"];

				this.fragment = sections[Math.floor((pos + 20) / height)];
			}, 10);
		}
		this.resizeWindow();
		this.logoSwap();
		this.viewportScroller.setOffset(() => {
			if (this.platform.IS_BROWSER && window.innerWidth <= 800) {
				return [0, 141 + 46];
			} else {
				return [0, 0];
			}
		});
		if (this.platform.IS_BROWSER) {
			this.scrollWatcher = setInterval(() => {
				if (this.lastScrollPosition != window.pageYOffset) {
					this.lastScrollPosition = window.pageYOffset;
					setTimeout(() => {
						this.router.navigate([]);
					});
				}

				this.scrollDown = window.pageYOffset > 0;
				if (this.scrollDown) {
					if (!this.scrolled) {
						const player = (<any>(
							this.animationBuilder
						))._renderer.engine.players.find(
							p => p.triggerName === "startAnimation"
						);
						if (player != null) {
							player.finish();
						}
					}
					this.scrolled = true;
				}
			}, 10);
		}
	}

	ngAfterViewInit(): void {
		if (this.platform.IS_BROWSER) {
			this.player = videojs(this.videoElement.nativeElement);
			this.player.src(this.video);
			this.player.poster(this.videoPoster);
			this.player.on("play", () => {
				this.centerPlayHide = true;
				setTimeout(() => (this.centerPlayExists = false), 1000);
			});
			//this.player.on("pause", () => {});
			this.player.on("ended", () => {
				this.player.currentTime(0);
				this.centerPlayExists = true;
				setTimeout(() => (this.centerPlayHide = false));
			});
			this.player.on(
				"durationchange",
				() => (this.videoDuration = this.player.duration())
			);
			this.player.on("timeupdate", () => {
				this.videoTime = this.player.currentTime();
				this.requestedSeek = null;
			});

			setTimeout(() => {
				this.aboutContentHeight = this.aboutIdentity.nativeElement.clientHeight;
				this.selectAbout(0);
			});
		}
	}

	ngOnDestroy(): void {
		clearTimeout(this.logoTimer);
		if (this.platform.IS_BROWSER) {
			window.removeEventListener("resize", this.resizeWindow);
			clearInterval(this.fragmentInterval);
			clearInterval(this.scrollWatcher);
		}
		this.viewportScroller.setOffset([0, 0]);
	}

	animationDone = false;
	private hideEverything = true;
	private lastScrollPosition = 0;
	private scrollWatcher: NodeJS.Timeout;
	private scrolled = false;
	private scrollDown = false;
	private desktop = true;

	// ==== menu ====

	fragmentInterval: NodeJS.Timeout;
	fragment: string;
	arrowClicked: boolean = false;
	expandMenu: boolean = false;

	// ==== headline ====

	asterisk: boolean = false;

	// ==== about ====

	@ViewChild("aboutIdentity") aboutIdentity: ElementRef;
	@ViewChild("aboutContent") aboutContent: ElementRef;

	aboutContentHeight = 0;

	selectAbout(section: number): void {
		// pause the current animation
		if (this.animation != null) this.animation.pause();

		// get the current progress
		const animationDuration = 500;
		let currentProgress = 1;
		if (this.animationStart != null)
			currentProgress = Math.min(
				(new Date().getTime() - this.animationStart) /
					animationDuration,
				1
			);

		// compute the current position
		this.animateFrom =
			this.cubicBezier(0.25, 0.1, 0.25, 1, currentProgress) *
				(this.animateTo - this.animateFrom) +
			this.animateFrom;
		this.animateTo = section;

		// destroy the current animation
		if (this.animation != null) this.animation.destroy();

		// create the new animations
		const animations = [];
		for (let i = 0; i < 4; i++) {
			function positionToAngle(position: number) {
				return ((position - i) / 4) * 360;
			}

			animations.push(
				query(
					"p.index" + i,
					animate(
						animationDuration + "ms ease",
						keyframes([
							style({
								transform:
									"rotateX(" +
									positionToAngle(this.animateFrom) +
									"deg)",
								offset: 0
							}),
							style({
								transform:
									"rotateX(" +
									positionToAngle(this.animateTo) +
									"deg)",
								offset: 1
							})
						])
					)
				)
			);
		}
		this.animation = this.animationBuilder
			.build([group(animations)])
			.create(this.aboutContent.nativeElement);

		// play the animation
		this.animation.play();
		this.animationStart = new Date().getTime();
	}

	private cubicBezier(
		p1x: number,
		p1y: number,
		p2x: number,
		p2y: number,
		x: number
	): number {
		// stolen from: https://stackoverflow.com/a/11697909/3171100
		const cx = 3.0 * p1x;
		const bx = 3.0 * (p2x - p1x) - cx;
		const ax = 1.0 - cx - bx;

		const cy = 3.0 * p1y;
		const by = 3.0 * (p2y - p1y) - cy;
		const ay = 1.0 - cy - by;

		const epsilon = 1e-6;

		function sampleCurveX(t) {
			return ((ax * t + bx) * t + cx) * t;
		}
		function sampleCurveY(t) {
			return ((ay * t + by) * t + cy) * t;
		}
		function sampleCurveDerivativeX(t) {
			return (3.0 * ax * t + 2.0 * bx) * t + cx;
		}
		function solveCurveX(x, epsilon) {
			let t0;
			let t1;
			let t2;
			let x2;
			let d2;
			let i;

			// First try a few iterations of Newton's method -- normally very fast.
			for (t2 = x, i = 0; i < 8; i++) {
				x2 = sampleCurveX(t2) - x;
				if (Math.abs(x2) < epsilon) return t2;
				d2 = sampleCurveDerivativeX(t2);
				if (Math.abs(d2) < epsilon) break;
				t2 = t2 - x2 / d2;
			}

			// No solution found - use bi-section
			t0 = 0.0;
			t1 = 1.0;
			t2 = x;

			if (t2 < t0) return t0;
			if (t2 > t1) return t1;

			while (t0 < t1) {
				x2 = sampleCurveX(t2);
				if (Math.abs(x2 - x) < epsilon) return t2;
				if (x > x2) t0 = t2;
				else t1 = t2;

				t2 = (t1 - t0) * 0.5 + t0;
			}

			// Give up
			return t2;
		}

		return sampleCurveY(solveCurveX(x, epsilon));
	}

	private animation: any;
	public animateTo = 0;
	private animateFrom = 0;
	private animationStart: number;

	// ==== video ====

	@ViewChild("videoElement") videoElement: ElementRef;
	@ViewChild("timeline") timeline: ElementRef;

	player: videojs.Player;
	centerPlayExists = true;
	centerPlayHide = false;
	muted = false;
	private videoDuration = 1;
	private videoTime = 0;
	private requestedSeek: number | null = null;

	videoPlay(): void {
		this.player.play();
	}

	videoPause(): void {
		this.player.pause();
	}

	videoMute(): void {
		this.muted = !this.muted;
		this.player.muted(this.muted);
	}

	private getRequestedSeek(x: number): number {
		const value =
			(x - this.timeline.nativeElement.getBoundingClientRect().x) /
			this.timeline.nativeElement.offsetWidth;

		return Math.max(0, Math.min(value, 1));
	}

	videoDragSeek(event: any): void {
		if (event.buttons == 1) {
			this.requestedSeek = this.getRequestedSeek(event.clientX);
		}
	}

	videoClickSeek(event: any): void {
		this.requestedSeek = this.getRequestedSeek(event.clientX);
		this.player.currentTime(this.player.duration() * this.requestedSeek);
	}

	seekPercentage(): number {
		return this.requestedSeek != null
			? this.requestedSeek
			: this.videoTime / this.videoDuration;
	}

	videoControlsInactive = false;
	videoControlsTimeout: NodeJS.Timer;

	videoControlsActivity(): void {
		this.videoControlsInactive = false;
		if (this.videoControlsTimeout != null)
			clearTimeout(this.videoControlsTimeout);
		this.videoControlsTimeout = setTimeout(() => {
			this.videoControlsInactive = true;
		}, 2500);
	}

	// ==== clients ====

	private clientsDisplayOrder: number[] = [];
	private logoTimer: NodeJS.Timeout;

	logoSwap(): void {
		clearTimeout(this.logoTimer);
		this.ngZone.runOutsideAngular(() => {
			this.logoTimer = setTimeout(
				() =>
					this.ngZone.run(() => {
						const index = Math.floor(
							Math.random() * this.clientsVisible.length
						);

						this.clientsBehind[index] = this.clientsVisible[index];
						this.clientsVisible[index] = this.nextClient();

						this.logoSwap();
					}),
				Math.max(Math.random() * 3 * 1000, 1000)
			);
		});
	}

	private resizeWindow(): void {
		if (!this.platform.IS_BROWSER) return;

		// get the number of logos we should be displaying at this screen size
		var columnCount = 1;
		const windowWidth = window.innerWidth;
		if (windowWidth >= 700) columnCount++;
		if (windowWidth >= 1000) columnCount++;
		if (windowWidth >= 1300) columnCount++;
		const logoCount = columnCount * this.rowCount;

		// add missing logos
		for (let i = this.clientsVisible.length - 1; i < logoCount; i++) {
			this.clientsVisible.push(this.nextClient());
			this.clientsBehind.push(null);
		}

		// remove overflow logos
		this.clientsVisible.length = logoCount;
		this.clientsBehind.length = logoCount;

		this.desktop = window.innerWidth > 800;

		if (this.aboutIdentity != null) {
			this.aboutContentHeight = this.aboutIdentity.nativeElement.offsetHeight;
		}
	}

	private nextClient(): number {
		let client;
		do {
			client = this.clientsDisplayOrder.shift();
			this.clientsDisplayOrder.push(client);
		} while (
			this.clientsVisible.indexOf(client) >= 0 ||
			this.clientsBehind.indexOf(client) >= 0
		); // make sure we don't return something that's already on the screen
		return client;
	}

	clients = [
		["Amsted.png", "AmstedRail"],
		["Bilfinger.png", "Bilfinger"],
		["ceasefire-newlogo.png", "CeaseFirePA"],
		["CRW.svg", "Capital Region Water"],
		["Cushman Wakefield.svg", "Cushman & Wakefield"],
		["DeSoto.svg", "DeSoto"],
		["Diakon.png", "Diakon"],
		["Diamond 6.svg", "Diamond 6"],
		["Dio of Ore.svg", ""],
		["ECF.svg", "Episcopal Church Foundation"],
		["FHCCP.png", "FHCCP"],
		["Gamut.svg", "Gamut"],
		["Hanselman.svg", "Hanselman Landscape & Gardens"],
		["HBG Academy.svg", "Harrisburg Academy"],
		["HealthcareLogo.png", "Healthcare"],
		["Hellenic.svg", "Hellenic Kouzina Greek Cafe"],
		["HKSB.png", "Hellen Keller Service for the Blind"],
		["ILR.svg", "U.S. Chamber Institute for Legal Reform"],
		["Layer 1.png", "IVES Equipment"],
		["Janus.png", "The Janus School"],
		["LCDS.svg", "Lancaster Country Day School"],
		["Mauell.svg", "Mauell"],
		["Midtown Scholar.svg", "Midtown Scholar Bookstore"],
		["NAES.svg", "National Association of Episcopal School"],
		["NSVRC.svg", "National Sexual Violence Resource Center"],
		["NYL.svg", "New York Life"],
		["Omni.svg", "Omni Fitness"],
		["PA Dio.svg", "The Episcopal Church of Central Pennsylvania"],
		["PA Heritage.svg", "Pennsylvania Heritage Foundation"],
		["PCB.svg", "Pennsylvania Certification Board"],
		["RB.svg", "RB"],
		["Sphere-logo.png", "Sphere"],
		["State Museum.svg", "The State Museum of Pennsylvania"],
		["Temple.svg", "Temple University"],
		["US Chamber.svg", "U.S. Chamber of Commerce"],
		["VTS.svg", "Virginia Theological Seminary"]
	];

	rowCount = 2;
	clientsBehind: number[] = [];
	clientsVisible: number[] = [];

	// ==== contact form ====

	@ViewChild("contactSection") contactSection: ElementRef;

	contact = { name: "", email: "", company: "", website: "", message: "" };
	contacted = false;

	contactSubmit(): void {
		this.http.post("/api/contact", this.contact).subscribe(() => {
			this.contacted = true;
			this.contactSection.nativeElement.scrollIntoView();
		});
	}
}
