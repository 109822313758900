<f-microsite site="business" color="#f05661"
	video="https://player.vimeo.com/external/393495504.m3u8?s=65e8968dc4fa41331846127c38704a818fd1aafa"
	videoPoster="/assets/images/video/business-poster.jpg">
	<ng-container headline>First impressions of your business are 94% design-related.</ng-container>
	<ng-container asterisk>Call us to upgrade your branding, website, brochure, presentation, or video.</ng-container>

	<ng-container about>
		Fathom helps your business go farther, faster, with turn-key graphic design and
		communications solutions that make your pitch, make it stick, and drive action.
	</ng-container>

	<ng-container about-identity>
		It all starts with your logo and visual identity. From startup to Fortune 100, Fathom
		designs awesome logos and extends them into visually striking communications.
	</ng-container>

	<ng-container about-design>
		We make your collateral do more damage—with graphic design that's second
		to none. Print design. Presentations. Advertising. And more.
	</ng-container>

	<ng-container about-development>
		Fathom’s web design solutions are beautiful, capable, and secure. We love
		building Wordpress websites, but we can help with most any environment.
	</ng-container>

	<ng-container about-video>
		Explainer videos. Short documentaries. Training videos. We film and animate, but
		most of all, we motivate.
	</ng-container>
</f-microsite>
