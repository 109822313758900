import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Platform } from "./platform.service";
import {
	LazyLoadDirective,
	LazyLoadDirectiveComponent
} from "./lazy-load.directive";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MicrositeComponent } from "./components/microsite/component";
import { BrComponent } from "./components/br/component";
import { StylisticComponent } from "./components/stylistic/component";
import { SemanticComponent } from "./components/semantic/component";
import { FooterComponent } from "./components/footer/component";
import { FadeinComponent } from "./components/fadein/component";
import { IndexPage } from "./pages/index.component";
import { GoodPage } from "./pages/good/component";
import { BusinessPage } from "./pages/business/component";
import { AgenciesPage } from "./pages/agencies/component";
import { _404Page } from "./pages/404.component";

@NgModule({
	declarations: [
		AppComponent,
		LazyLoadDirective,
		LazyLoadDirectiveComponent,

		MicrositeComponent,
		BrComponent,
		StylisticComponent,
		SemanticComponent,
		FooterComponent,
		FadeinComponent,

		IndexPage,
		GoodPage,
		BusinessPage,
		AgenciesPage,
		_404Page
	],
	imports: [
		CommonModule,
		BrowserModule.withServerTransition({ appId: "serverApp" }),
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		AppRoutingModule
	],
	providers: [Platform],
	bootstrap: [AppComponent],
	entryComponents: [LazyLoadDirectiveComponent]
})
export class AppModule {}
