import { Component, Input } from "@angular/core";
import { ViewportScroller } from "@angular/common";

@Component({
	selector: "f-footer",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	host: {
		"[style.color]": "color"
	}
})
export class FooterComponent {
	constructor(public viewportScroller: ViewportScroller) {}

	@Input() color = "gray";

	year = new Date().getFullYear();

	showTerms = false;
	showPrivacy = false;

	showPolicy(policy: string): void {
		this.showTerms = this.showTerms ? false : policy == "terms";
		this.showPrivacy = this.showPrivacy ? false : policy == "privacy";

		setTimeout(() => this.viewportScroller.scrollToAnchor(policy));
	}
}
