import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	Input,
	NgZone,
	ElementRef,
	ViewChild
} from "@angular/core";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { Platform } from "src/app/platform.service";
import { ViewportScroller } from "@angular/common";
import {
	trigger,
	state,
	style,
	animate,
	transition,
	keyframes,
	query,
	stagger,
	group,
	sequence,
	AnimationBuilder
} from "@angular/animations";
import { Router } from "@angular/router";

@Component({
	templateUrl: "./index.component.html",
	styleUrls: ["./index.component.scss"]
})
export class IndexPage implements OnInit {
	constructor(
		private title: Title,
		private meta: Meta,
		public platform: Platform,
		public sanitizer: DomSanitizer,
		public viewportScroller: ViewportScroller,
		private ngZone: NgZone,
		private elementRef: ElementRef,
		public animationBuilder: AnimationBuilder,
		public router: Router
	) {}

	ngOnInit(): void {
		this.viewportScroller.scrollToPosition([0, 0]);
		this.title.setTitle(
			"Full service design firm: for business | for good | for agencies"
		);
		this.meta.updateTag({
			property: "og:title",
			content:
				"Full service design firm: for business | for good | for agencies"
		});
		this.meta.updateTag({
			name: "description",
			content:
				"Businesses, nonprofits, and agencies from New York to D.C. and beyond turn to Fathom for logo design, video production, presentation design, graphic design, web design, programming, and more. Just pick your path to see what we can do for you."
		});
		this.meta.updateTag({
			property: "og:description",
			content:
				"Businesses, nonprofits, and agencies from New York to D.C. and beyond turn to Fathom for logo design, video production, presentation design, graphic design, web design, programming, and more. Just pick your path to see what we can do for you."
		});
		this.meta.updateTag({
			property: "og:image",
			content: "/assets/images/ogp/home.png"
		});

		this.viewportScroller.scrollToPosition([0, 0]);
	}

	navigatedSite: "business" | "good" | "agencies" | null;

	navigate(site: "business" | "good" | "agencies"): boolean {
		if (window.innerWidth <= 800) {
			this.router.navigate(["/" + site]);
		}
		this.viewportScroller.scrollToPosition([0, 0]);
		this.navigatedSite = site;
		setTimeout(() => {
			this.router.navigate(["/" + site]);
		}, 700);
		return false;
	}
}
