<header [style.background-color]="color">
	<div class="content">
		<div class="logo">
			<h1>
				<a routerLink="/" routerLinkActive="_link-active">
					<span class="f letter">F</span>
					<span class="afterF">
						<span class="letter">a</span>
						<span class="letter">t</span>
						<span class="letter">h</span>
						<span class="letter">o</span>
						<span class="letter">m</span>
					</span>
				</a>
			</h1>

			<nav *ngIf="animationDone">
				<ul>
					<li>
						<a routerLinkActive="active" routerLink="/business">for businesses</a>
					</li>
					<li>
						<a routerLinkActive="active" routerLink="/good">for good</a>
					</li>
					<li>
						<a routerLinkActive="active" routerLink="/agencies">for agencies</a>
					</li>
				</ul>
			</nav>
		</div>

		<nav [class.expanded]="expandMenu">
			<div class="handle handle1" (click)="expandMenu = false"></div>
			<ul (click)="expandMenu = false">
				<li>
					<a routerLink="." fragment="about" routerLinkActive="_link-active">About</a>
				</li>
				<li>
					<a routerLink="." fragment="work" routerLinkActive="_link-active">Work</a>
				</li>
				<li>
					<a routerLink="." fragment="clients" routerLinkActive="_link-active">Clients</a>
				</li>
				<li>
					<a routerLink="." fragment="contact" routerLinkActive="_link-active">Contact</a>
				</li>
			</ul>
			<div class="handle handle2" (click)="expandMenu = false"></div>
		</nav>
		<div class="expandButton" [class.expanded]="expandMenu" (click)="expandMenu = true"></div>
	</div>
</header>

<div class="arrowContainerContainerContainer"
	[class.bottom]="platform.IS_BROWSER ? window.pageYOffset / window.innerHeight >= 4 : false">
	<div class="arrowContainerContainer">
		<div class="arrowContainer">
			<a class="arrow" [class.clicked]="arrowClicked" [fragment]="fragment" (click)="arrowClicked = true"
				routerLink="." routerLinkActive="_link-active">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 77">
					<path style="fill:white"
						d="M38.5,0A38.5,38.5,0,1,0,77,38.5,38.5,38.5,0,0,0,38.5,0Zm0,63.7h0L14,39.16l5.66-5.65,15,15V13.3h7.66V48.56l15-15L63,39.18Z">
					</path>
				</svg>
			</a>
		</div>
	</div>
</div>

<main>
	<div class="section" id="top">
		<p class="headline">
			<span style="margin-left:-2px"></span>
			<ng-content select="[headline]"></ng-content><span class="aContainer" #parente><img #asteriske
					src="/assets/images/asterisk-{{site}}.svg" (click)="asterisk = !asterisk; $event.stopPropagation()"
					[class.expand]="asterisk"
					[attr.style]="sanitizer.bypassSecurityTrustStyle(((platform.IS_BROWSER &amp;&amp; asterisk) ? '-webkit-transform: translate(calc(-1 * ' + (window.pageXOffset + parente.getBoundingClientRect().left + asteriske.offsetLeft) + 'px - 40vw + 50vw), calc(-1 * ' + (window.pageYOffset + parente.getBoundingClientRect().top + asteriske.offsetTop) + 'px - 40vw + 50vh)) rotate(0deg); transform: translate(calc(-1 * ' + (window.pageXOffset + parente.getBoundingClientRect().left + asteriske.offsetLeft) + 'px - 40vw + 50vw), calc(-1 * ' + (window.pageYOffset + parente.getBoundingClientRect().top + asteriske.offsetTop) + 'px - 40vw + 50vh)) rotate(0deg)' : '-webkit-transform:translate(0, calc(-0.66em)) rotate(-390deg); transform:translate(0, calc(-0.66em)) rotate(-390deg)') + '; transition:-webkit-transform 1s, transform 1s, width 1s')" /></span>
			<span class="aSeo"><ng-content select="[asterisk]"></ng-content></span>
		</p>
	</div>

	<div class="section" id="about">
		<f-fadein>
			<div>
				<h2>About</h2>
				<div class="content">
					<p class="left">
						<ng-content select="[about]"></ng-content>
					</p>
					<div class="bar"></div>
					<div class="right" #aboutContent>
						<h3 (mouseover)="selectAbout(0)" [class.active]="animateTo == 0">
							Identity
						</h3>
						<div class="aboutContainer" [class.active]="animateTo == 0">
							<p #aboutIdentity class="index0"
								[style.transform-origin]="'center center -' + aboutContentHeight/2 + 'px'">
								<ng-content select="[about-identity]"></ng-content>
							</p>
						</div>

						<h3 (mouseover)="selectAbout(1)" [class.active]="animateTo == 1">
							Design
						</h3>
						<div class="aboutContainer" [class.active]="animateTo == 1">
							<p #aboutDesign class="index1"
								[style.transform-origin]="'center center -' + aboutContentHeight/2 + 'px'">
								<ng-content select="[about-design]"></ng-content>
							</p>
						</div>

						<h3 (mouseover)="selectAbout(2)" [class.active]="animateTo == 2">
							Development
						</h3>
						<div class="aboutContainer" [class.active]="animateTo == 2">
							<p #aboutDevelopment class="index2"
								[style.transform-origin]="'center center -' + aboutContentHeight/2 + 'px'">
								<ng-content select="[about-development]"></ng-content>
							</p>
						</div>

						<h3 (mouseover)="selectAbout(3)" [class.active]="animateTo == 3">
							Video
						</h3>
						<div class="aboutContainer" [class.active]="animateTo == 3">
							<p #aboutVideo class="index3"
								[style.transform-origin]="'center center -' + aboutContentHeight/2 + 'px'">
								<ng-content select="[about-video]"></ng-content>
							</p>
						</div>
					</div>
				</div>
			</div>
		</f-fadein>
	</div>

	<div class="section" id="work" (mousemove)="videoControlsActivity()" (touchstart)="videoControlsActivity()"
		(touchmove)="videoControlsActivity()">
		<f-fadein>
			<video playsinline #videoElement tabindex="0"></video>
			<button *ngIf="centerPlayExists" class="videoPlay" [style.color]="color" [class.hide]="centerPlayHide"
				(click)="videoPlay()">
				<span>Play</span>
			</button>
			<div class="controls" [class.inactive]="videoControlsInactive && !player.paused()">
				<button class="pause" (click)="videoPause()">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.61 23.37">
						<title>Pause</title>
						<rect [style.fill]="color" x="11.79" width="5.82" height="23.37" />
						<rect [style.fill]="color" width="5.82" height="23.37" />
					</svg>
				</button>
				<div class="separator" [style.background-color]="color"></div>
				<button class="play" (click)="videoPlay()">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.24 23.37">
						<title>Play</title>
						<polygon [style.fill]="color" points="20.24 11.68 0 0 0 23.37 20.24 11.68" />
					</svg>
				</button>
				<div class="separator" [style.background-color]="color"></div>
				<button class="mute" (click)="videoMute()">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.5 41"
						style="enable-background:new 0 0 29.5 41;" xml:space="preserve">
						<g>
							<path [style.fill]="color"
								d="M20.1,10c-0.7-2.3-2.8-4-5.3-4c-3.1,0-5.5,2.5-5.5,5.5V18c0,0.8,0.2,1.6,0.5,2.3L20.1,10z" />
							<path [style.fill]="color" d="M14.8,23.5c3.1,0,5.5-2.5,5.5-5.5v-1.4l8.1-8.1c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0L1.2,32.3
		c-0.5,0.5-0.5,1.2,0,1.7c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l7-7c1.1,0.6,2.4,1,3.7,1.2v4c-1.8,0.4-3.2,0.6-3.5,2.1
		c-0.1,0.3,0.2,0.5,0.4,0.5H19c0.3,0,0.5-0.3,0.5-0.5c-0.3-1.5-1.7-1.7-3.5-2.1v-4c5.1-0.6,9.1-5,9.1-10.3v-1c0-0.7-0.5-1.2-1.2-1.2
		c-0.7,0-1.2,0.5-1.2,1.2v1c0,4.4-3.6,7.9-7.9,7.9c-1.1,0-2.2-0.2-3.1-0.6l1.9-1.9C13.9,23.4,14.3,23.5,14.8,23.5z" />
							<path [style.fill]="color" d="M8,22.1c-0.7-1.2-1.1-2.6-1.1-4.1v-1c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2v1c0,2.2,0.7,4.2,1.8,5.9
		L8,22.1z" />
						</g>
					</svg>
				</button>
				<div class="timeline" (mousemove)="videoDragSeek($event)" (click)="videoClickSeek($event)" #timeline>
					<div class="full" [style.background-color]="color"></div>
					<div class="progress" [style.background-color]="color" [style.width.%]="seekPercentage() * 100">
					</div>
					<div class="scrubber" [style.background-color]="color" [style.left.%]="seekPercentage() * 100">
					</div>
				</div>
			</div>
		</f-fadein>
	</div>

	<div class="section" id="clients">
		<f-fadein>
			<div>
				<h2>Clients</h2>
				<ul class="clients">
					<li [class.visible]="clientsVisible.indexOf(i) &gt;= 0"
						[class.has-behind]="clientsBehind[clientsVisible.indexOf(i)] != null"
						[class.behind]="clientsBehind.indexOf(i) &gt;= 0"
						[style.grid-column]="clientsVisible.indexOf(i) &gt;= 0 ? Math.floor(clientsVisible.indexOf(i) / rowCount) + 1 : (clientsBehind.indexOf(i) &gt;= 0 ? Math.floor(clientsBehind.indexOf(i) / rowCount) + 1 : null)"
						[style.grid-row]="clientsVisible.indexOf(i) &gt;= 0 ? Math.floor(clientsVisible.indexOf(i) % rowCount) + 1 : (clientsBehind.indexOf(i) &gt;= 0 ? Math.floor(clientsBehind.indexOf(i) % rowCount) + 1 : null)"
						*ngFor="let client of clients; index as i">
						<img *lazyLoad src="/assets/images/clients/{{client[0]}}" alt="{{client[1]}}" />
					</li>
				</ul>
			</div>
		</f-fadein>
	</div>

	<div class="section" id="contact" [class.contacted]="contacted" #contactSection>
		<f-fadein>
			<h2><span>Contact</span></h2>
			<div class="content">
				<p class="message">
					<span>Drop us a line and let's get started on your next
						project.</span>
				</p>
				<form #contactForm="ngForm" (ngSubmit)="contactSubmit()">
					<label for="name">Name</label>
					<input type="text" id="name" name="name" required [(ngModel)]="contact.name" />

					<label for="email">Email</label>
					<input type="email" id="email" name="email" required [(ngModel)]="contact.email" />

					<label for="company">
						<ng-container *ngIf="site == 'good'; else notGood">Organization</ng-container>
						<ng-template #notGood>Company</ng-template>
					</label>
					<input type="text" id="company" name="company" required [(ngModel)]="contact.company" />

					<label for="website">Website</label>
					<input type="text" id="website" name="website" [(ngModel)]="contact.website" />

					<label for="message">Message</label>
					<textarea id="message" name="message" required [(ngModel)]="contact.message"></textarea>

					<button type="submit" [style.color]="color" [disabled]="!contactForm.valid">
						Deliver
					</button>
				</form>
			</div>

			<div class="contactSuccess desktop" *ngIf="contacted">
				<p class="contacted">
					<span>Contact<span class="ed">ed!</span></span>
				</p>
				<p class="contactMade">Contact made! We'll be in touch.</p>
			</div>

			<div class="contactSuccess mobile" *ngIf="contacted">
				<p class="contacted">Contact made!</p>
				<p class="contactMade">We'll be in touch.</p>
			</div>
		</f-fadein>
	</div>
</main>

<f-footer [color]="color"></f-footer>
