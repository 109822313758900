<footer>
	<div class="content">
		<div class="copyright">©{{year}} Fathom. All rights reserved.</div>
		<div class="links">
			<address>
				308 East Main Street
				<span class="barHide"> | </span>
				Mechanicsburg, PA 17055
			</address>
			<span class="barHide"> | </span>
			<a href="tel:+17172609502">717-260-9502</a> |
			<a href="tel:+18885151635">888-515-1635</a>
			<span class="barHide"> | </span>
			<button (click)="showPolicy('terms')">Terms & Conditions</button> |
			<button (click)="showPolicy('privacy')">Privacy</button>
		</div>
		<div class="social">
			<a href="https://www.linkedin.com/company/fathom-studio/">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path
						[style.fill]="color"
						d="M22.22,0H1.77A1.75,1.75,0,0,0,0,1.73V22.27A1.75,1.75,0,0,0,1.77,24H22.22A1.76,1.76,0,0,0,24,22.27V1.73A1.75,1.75,0,0,0,22.22,0ZM7.12,20.45H3.56V9H7.12Zm-1.78-13A2.07,2.07,0,1,1,7.4,5.37,2.07,2.07,0,0,1,5.34,7.43Zm15.11,13H16.89V14.88c0-1.33,0-3-1.85-3s-2.13,1.45-2.13,2.94v5.67H9.35V9h3.42v1.56h0a3.75,3.75,0,0,1,3.37-1.85c3.61,0,4.27,2.37,4.27,5.46Z"
					></path>
				</svg>
			</a>
			<a href="https://www.instagram.com/fathomstudio/">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path
						[style.fill]="color"
						d="M12,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Z"
					></path>
					<path
						[style.fill]="color"
						d="M12,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z"
					></path>
					<circle
						[style.fill]="color"
						cx="18.41"
						cy="5.59"
						r="1.44"
					></circle>
				</svg>
			</a>
			<a href="https://www.facebook.com/FathomStudio/">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.85">
					<path
						[style.fill]="color"
						d="M24,12A12,12,0,1,0,10.12,23.85V15.47h-3V12h3V9.36c0-3,1.8-4.67,4.54-4.67a18.71,18.71,0,0,1,2.68.23v3H15.83a1.73,1.73,0,0,0-1.95,1.87V12H17.2l-.53,3.47H13.88v8.38A12,12,0,0,0,24,12Z"
					></path>
				</svg>
			</a>
		</div>
	</div>

	<div class="policy" id="terms" *ngIf="showTerms">
		<h2>Fathom Terms of Service ("Terms")</h2>
		<p>Last updated: (3/6/20)</p>
		<p>
			Please read these Terms of Service ("Terms", "Terms of Service")
			carefully before using the www.fathomstudio.com, operated by Fathom
			("us", "we", or "our").
		</p>
		<p>
			Your access to and use of the Service is conditioned on your
			acceptance of and compliance with these Terms. These Terms apply to
			all visitors, users and others who access or use the Service.
		</p>
		<p>
			<strong
				>By accessing or using the Service you agree to be bound by
				these Terms. If you disagree with any part of the terms then you
				may not access the Service.</strong
			>
		</p>

		<h3>Termination</h3>
		<p>
			We may terminate or suspend access to our Service immediately,
			without prior notice or liability, for any reason whatsoever,
			including without limitation if you breach the Terms.
		</p>
		<p>
			All provisions of the Terms which by their nature should survive
			termination shall survive termination, including, without
			limitation, ownership provisions, warranty disclaimers, indemnity
			and limitations of liability.
		</p>

		<h3>Links To Other Web Sites</h3>
		<p>
			Our Service may contain links to third-party web sites or services
			that are not owned or controlled by Fathom.
		</p>
		<p>
			Fathom has no control over, and assumes no responsibility for, the
			content, privacy policies, or practices of any third party web sites
			or services. You further acknowledge and agree that Fathom shall not
			be responsible or liable, directly or indirectly, for any damage or
			loss caused or alleged to be caused by or in connection with use of
			or reliance on any such content, goods or services available on or
			through any such web sites or services.
		</p>

		<h3>Changes</h3>
		<p>
			We reserve the right, at our sole discretion, to modify or replace
			these Terms at any time. If a revision is material we will try to
			provide at least 30 days' notice prior to any new terms taking
			effect. What constitutes a material change will be determined at our
			sole discretion.
		</p>

		<h3>Contact Us</h3>
		<p>If you have any questions about these Terms, please contact us.</p>
	</div>

	<div class="policy" id="privacy" *ngIf="showPrivacy">
		<h2>Fathom Privacy Policy</h2>
		<p>Last updated: (3/6/20)</p>
		<p>
			Fathom ("us", "we", or "our") operates www.fathomstudio.com (the
			"Site"). This page informs you of our policies regarding the
			collection, use and disclosure of Personal Information we receive
			from users of the Site.
		</p>
		<p>
			We use your Personal Information only for providing and improving
			the Site. By using the Site, you agree to the collection and use of
			information in accordance with this policy.
		</p>

		<h3>Information Collection And Use</h3>
		<p>
			We collect several different types of information for various
			purposes to provide and improve our Service to you.
		</p>

		<h4>Types of Data Collected</h4>
		<h5>Personal Data</h5>
		<p>
			While using our Service, we may ask you to provide us with certain
			personally identifiable information that can be used to contact or
			identify you ("Personal Data"). Personally identifiable information
			may include, but is not limited to:
		</p>
		<ul>
			<li>Email address</li>
			<li>First name and last name</li>
			<li>Phone number</li>
			<li>Address, State, Province, ZIP/Postal code, City</li>
			<li>Cookies and Usage Data</li>
		</ul>
		<p>
			We may use your Personal Data to contact you with newsletters,
			marketing or promotional materials and other information that may be
			of interest to you. You may opt out of receiving any, or all, of
			these communications from us by following the unsubscribe link or
			instructions provided in any email we send or by contacting us.
		</p>

		<h5>Usage Data</h5>
		<p>
			We may also collect information how the Service is accessed and used
			("Usage Data").
		</p>
		<p>
			This Usage Data may include information such as your computer's
			Internet Protocol address (e.g. IP address), operating system,
			Internet browser that you use, browser version, the pages of our
			Service that you visit, the time and date of your visit, the time
			spent on those pages, unique device identifiers and other diagnostic
			data.
		</p>

		<h5>Tracking Cookies Data</h5>
		<p>
			We use cookies and similar tracking technologies to track the
			activity on our Service and hold certain information.
		</p>
		<p>
			Cookies are files with small amount of data which may include an
			anonymous unique identifier. Cookies are sent to your browser from a
			website and stored on your device. Tracking technologies also used
			are beacons, tags, and scripts to collect and track information and
			to improve and analyze our Service.
		</p>
		<p>
			You can instruct your browser to refuse all cookies or to indicate
			when a cookie is being sent. However, if you do not accept cookies,
			you may not be able to use some portions of our Service.
		</p>

		<p>Examples of Cookies we use:</p>
		<ul>
			<li>
				Session Cookies. We use Session Cookies to operate our Service.
			</li>
			<li>
				Preference Cookies. We use Preference Cookies to remember your
				preferences and various settings.
			</li>
			<li>
				Security Cookies. We use Security Cookies for security purposes.
			</li>
		</ul>

		<h3>Use of Data</h3>
		<p>Fathom uses the collected data for various purposes:</p>
		<ul>
			<li>To provide and maintain our Service</li>
			<li>To notify you about changes to our Service</li>
			<li>
				To allow you to participate in interactive features of our
				Service when you choose to do so
			</li>
			<li>To provide customer support</li>
			<li>
				To gather analysis or valuable information so that we can
				improve our Service
			</li>
			<li>To monitor the usage of our Service</li>
			<li>To detect, prevent and address technical issues</li>
			<li>
				To provide you with news, special offers and general information
				about other goods, services and events which we offer that are
				similar to those that you have already purchased or enquired
				about unless you have opted not to receive such information
			</li>
		</ul>

		<h4>Remarketing</h4>
		<p>
			In order to tailor our advertising to those who are familiar with
			our product, we use third party vendors such as Google and Facebook
			to provide remarketing services. Remarketing displays relevant ads
			tailored to you based on your visiting the Fathom.com site. To do
			this, a piece of code called a cookie is placed on your machine.
			This code simply indicates to our remarketing partners that you
			visited Fathom and to display ads for you elsewhere that are related
			to Fathom. It does not identify you in any way, nor does it provide
			access to your computer.
		</p>
		<p>
			You can opt out of Google Marketing Platform's use of cookies by
			visiting the Google Marketing Platform opt-out page or the Network
			Advertising Initiative opt-out page. You can opt out of
			interest-based ads on Facebook by visiting the Settings section of
			your Facebook account. You will still see ads, but they may not be
			as relevant to you.
		</p>

		<h3>
			Legal Basis for Processing Personal Data Under General Data
			Protection Regulation (GDPR)
		</h3>
		<p>
			If you are from the European Economic Area (EEA), Fathom legal basis
			for collecting and using the personal information described in this
			Privacy Policy depends on the Personal Data we collect and the
			specific context in which we collect it.
		</p>
		<p>Fathom may process your Personal Data because:</p>
		<ul>
			<li>We need to perform a contract with you</li>
			<li>You have given us permission to do so</li>
			<li>
				The processing is in our legitimate interests and it's not
				overridden by your rights
			</li>
			<li>For payment processing purposes</li>
			<li>To comply with the law</li>
		</ul>

		<h3>Retention of Data</h3>
		<p>
			Fathom will retain your Personal Data only for as long as is
			necessary for the purposes set out in this Privacy Policy. We will
			retain and use your Personal Data to the extent necessary to comply
			with our legal obligations (for example, if we are required to
			retain your data to comply with applicable laws), resolve disputes,
			and enforce our legal agreements and policies.
		</p>
		<p>
			Fathom will also retain Usage Data for internal analysis purposes.
			Usage Data is generally retained for a shorter period of time,
			except when this data is used to strengthen the security or to
			improve the functionality of our Service, or we are legally
			obligated to retain this data for longer time periods.
		</p>

		<h3>Transfer of Data</h3>
		<p>
			Your information, including Personal Data, may be transferred to —
			and maintained on — computers located outside of your state,
			province, country or other governmental jurisdiction where the data
			protection laws may differ than those from your jurisdiction.
		</p>
		<p>
			If you are located outside United States and choose to provide
			information to us, please note that we transfer the data, including
			Personal Data, to United States and process it there.
		</p>
		<p>
			Your consent to this Privacy Policy followed by your submission of
			such information represents your agreement to that transfer.
		</p>
		<p>
			Fathom will take all steps reasonably necessary to ensure that your
			data is treated securely and in accordance with this Privacy Policy
			and no transfer of your Personal Data will take place to an
			organization or a country unless there are adequate controls in
			place including the security of your data and other personal
			information.
		</p>

		<h2>Disclosure Of Data</h2>
		<h3>Business Transaction</h3>
		<p>
			If Fathom is involved in a merger, acquisition or asset sale, your
			Personal Data may be transferred. We will provide notice before your
			Personal Data is transferred and becomes subject to a different
			Privacy Policy.
		</p>

		<h3>Disclosure for Law Enforcement</h3>
		<p>
			Under certain circumstances, Fathom may be required to disclose your
			Personal Data if required to do so by law or in response to valid
			requests by public authorities (e.g. a court or a government
			agency).
		</p>

		<h3>Legal Requirements</h3>
		<p>
			Fathom may disclose your Personal Data in the good faith belief that
			such action is necessary to:
		</p>
		<ul>
			<li>To comply with a legal obligation</li>
			<li>To protect and defend the rights or property of Fathom</li>
			<li>
				To prevent or investigate possible wrongdoing in connection with
				the Service
			</li>
			<li>
				To protect the personal safety of users of the Service or the
				public
			</li>
			<li>To protect against legal liability</li>
		</ul>

		<h3>Security of Data</h3>
		<p>
			The security of your data is important to us, but remember that no
			method of transmission over the Internet, or method of electronic
			storage is 100% secure. While we strive to use commercially
			acceptable means to protect your Personal Data, we cannot guarantee
			its absolute security.
		</p>

		<h3>"Do Not Track" Signals</h3>
		<p>
			We do not support Do Not Track ("DNT"). Do Not Track is a preference
			you can set in your web browser to inform websites that you do not
			want to be tracked.
		</p>
		<p>
			You can enable or disable Do Not Track by visiting the Preferences
			or Settings page of your web browser.
		</p>

		<h3>
			Your Data Protection Rights Under General Data Protection Regulation
			(GDPR)
		</h3>
		<p>
			If you are a resident of the European Economic Area (EEA), you have
			certain data protection rights. Fathom aims to take reasonable steps
			to allow you to correct, amend, delete, or limit the use of your
			Personal Data.
		</p>
		<p>
			If you wish to be informed what Personal Data we hold about you and
			if you want it to be removed from our systems, please contact us.
		</p>
		<p>
			In certain circumstances, you have the following data protection
			rights:
		</p>
		<p>
			<strong
				>The right to access, update or to delete the information we
				have on you.</strong
			>
			Whenever made possible, you can access, update or request deletion
			of your Personal Data directly within your account settings section.
			If you are unable to perform these actions yourself, please contact
			us to assist you.
		</p>
		<p>
			<strong>The right of rectification.</strong> You have the right to
			have your information rectified if that information is inaccurate or
			incomplete.
		</p>
		<p>
			<strong>The right to object.</strong> You have the right to object
			to our processing of your Personal Data.
		</p>
		<p>
			<strong>The right of restriction.</strong> You have the right to
			request that we restrict the processing of your personal
			information.
		</p>
		<p>
			<strong>The right to data portability.</strong> You have the right
			to be provided with a copy of the information we have on you in a
			structured, machine-readable and commonly used format.
		</p>
		<p>
			<strong>The right to withdraw consent.</strong> You also have the
			right to withdraw your consent at any time where Fathom relied on
			your consent to process your personal information.
		</p>
		<p>
			Please note that we may ask you to verify your identity before
			responding to such requests.
		</p>
		<p>
			You have the right to complain to a Data Protection Authority about
			our collection and use of your Personal Data. For more information,
			please contact your local data protection authority in the European
			Economic Area (EEA).
		</p>

		<h3>Service Providers</h3>
		<p>
			We may employ third party companies and individuals to facilitate
			our Service ("Service Providers"), to provide the Service on our
			behalf, to perform Service-related services or to assist us in
			analyzing how our Service is used.
		</p>
		<p>
			These third parties have access to your Personal Data only to
			perform these tasks on our behalf and are obligated not to disclose
			or use it for any other purpose.
		</p>

		<h4>Analytics</h4>
		<p>
			We may use third-party Service Providers to monitor and analyze the
			use of our Service.
		</p>

		<h5>Google Analytics</h5>
		<p>
			Google Analytics is a web analytics service offered by Google that
			tracks and reports website traffic. Google uses the data collected
			to track and monitor the use of our Service. This data is shared
			with other Google services. Google may use the collected data to
			contextualize and personalize the ads of its own advertising
			network.
		</p>
		<p>
			You can opt-out of having made your activity on the Service
			available to Google Analytics by installing the Google Analytics
			opt-out browser add-on. The add-on prevents the Google Analytics
			JavaScript (ga.js, analytics.js, and dc.js) from sharing information
			with Google Analytics about visits activity.
		</p>
		<p>
			For more information on the privacy practices of Google, please
			visit the Google Privacy Terms web page:
			<a href="http://www.google.com/intl/en/policies/privacy/"
				>http://www.google.com/intl/en/policies/privacy/</a
			>
		</p>

		<h4>Payments</h4>
		<p>
			We may provide paid products and/or services within the Service. In
			that case, we use third-party services for payment processing (e.g.
			payment processors).
		</p>
		<p>
			Payment information is provided to our third-party payment
			processors whose use of your personal information is governed by
			their Privacy Policy. These payment processors adhere to the
			standards set by PCI-DSS as managed by the PCI Security Standards
			Council, which is a joint effort of brands like Visa, Mastercard,
			American Express and Discover. PCI-DSS requirements help ensure the
			secure handling of payment information.
		</p>
		<p>The payment processors we work with are:</p>

		<h5>BluePay</h5>
		<p>
			Their Privacy Policy can be viewed at
			<a href="https://www.bluepay.com/privacy-policy/"
				>https://www.bluepay.com/privacy-policy/</a
			>
		</p>

		<h5>Stripe</h5>
		<p>
			Their Privacy Policy can be viewed at
			<a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
		</p>

		<h5>PayPal or Braintree</h5>
		<p>
			Their Privacy Policy can be viewed at
			<a href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
				>https://www.paypal.com/webapps/mpp/ua/privacy-full</a
			>
		</p>

		<h3>Links to Other Sites</h3>
		<p>
			Our Service may contain links to other sites that are not operated
			by us. If you click on a third party link, you will be directed to
			that third party's site. We strongly advise you to review the
			Privacy Policy of every site you visit.
		</p>
		<p>
			We have no control over and assume no responsibility for the
			content, privacy policies, or practices of any third party sites or
			services.
		</p>

		<h3>Children's Privacy</h3>
		<p>
			Our Service does not address anyone under the age of 18
			("Children").
		</p>
		<p>
			We do not knowingly collect personally identifiable information from
			anyone under the age of 18. If you are a parent or guardian and you
			are aware that your Children has provided us with Personal Data,
			please contact us. If we become aware that we have collected
			Personal Data from children without verification of parental
			consent, we take steps to remove that information from our servers.
		</p>

		<h3>Changes to This Privacy Policy</h3>
		<p>
			We may update our Privacy Policy from time to time. We will notify
			you of any changes by posting the new Privacy Policy on this page.
		</p>
		<p>
			We will let you know via email and/or a prominent notice on our
			Service, prior to the change becoming effective and update the
			"effective date" at the top of this Privacy Policy.
		</p>
		<p>
			You are advised to review this Privacy Policy periodically for any
			changes. Changes to this Privacy Policy are effective when they are
			posted on this page.
		</p>

		<h3>Contact Us</h3>
		<p>
			If you have any questions about this Privacy Policy, please contact
			us by email at
			<a href="mailto:support@fathomstudio.com"
				>support@fathomstudio.com</a
			>
		</p>
	</div>
</footer>
