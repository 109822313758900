import {
  Component,
  ComponentFactoryResolver,
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import { Platform } from "./platform.service";

@Directive({ selector: "[lazyLoad]" })
export class LazyLoadDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    // create placeholder component
    const factory = this.resolver.resolveComponentFactory(
      LazyLoadDirectiveComponent
    );
    const componentRef = this.viewContainer.createComponent(factory);

    if (this.platform.IS_BROWSER) {
      // we're running client-side

      // obtain a reference to the DOM element
      const nativeElement = componentRef.location.nativeElement;

      // wait for placeholder to intersect
      const observer = new IntersectionObserver(
        (entries, observer) => {
          if (entries[0].isIntersecting) {
            // placeholder was intersected, replace it with the actual content
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            observer.unobserve(nativeElement);
          }
        },
        {
          rootMargin: "0px 0px 10% 0px",
          threshold: 0
        }
      );

      observer.observe(nativeElement);
    } else {
      // we're running server-side, need to provide a fallback in case there is noscript client-side
      const component = componentRef.instance;
      component.content = this.templateRef;
    }
  }
}

@Component({
  selector: "lazy-placeholder",
  template: `
    <noscript *ngIf="content != null">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </noscript>
  `
})
export class LazyLoadDirectiveComponent {
  public content: TemplateRef<any> | null = null;
}
