import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
	templateUrl: "./component.html"
})
export class AgenciesPage implements OnInit {
	constructor(private title: Title, private meta: Meta) {}

	ngOnInit(): void {
		this.title.setTitle(
			"Identity, design, development, and video | Fathom for agencies"
		);
		this.meta.updateTag({
			property: "og:title",
			content:
				"Identity, design, development, and video | Fathom for agencies"
		});
		this.meta.updateTag({
			name: "description",
			content:
				"From New York to D.C. and beyond, Fathom works with PR firms, marketing agencies, lobbyists, and more to quietly extend their design capabilities. We're your unsung hero."
		});
		this.meta.updateTag({
			property: "og:description",
			content:
				"From New York to D.C. and beyond, Fathom works with PR firms, marketing agencies, lobbyists, and more to quietly extend their design capabilities. We're your unsung hero."
		});
		this.meta.updateTag({
			property: "og:image",
			content: "/assets/images/ogp/agencies.png"
		});
	}
}
