<div
	class="page"
	[class.navigated]="navigatedSite != null"
	[class.navigated-business]="navigatedSite == 'business'"
	[class.navigated-good]="navigatedSite == 'good'"
	[class.navigated-agencies]="navigatedSite == 'agencies'"
>
	<div class="channel" (click)="navigate('business')">
		<h2>
			<span>F<span>athom</span><f-semantic>:</f-semantic></span>
			<span>for business</span>
		</h2>
		<p>
			<span class="desktop"
				>Attract more customers with great graphic design.</span
			>
			<f-stylistic
				class="mobile"
				after="Let’s kick some ^#@&amp;%."
			></f-stylistic>
		</p>
		<a class="go" href="/business" (click)="navigate('business')"
			><span>Go</span></a
		>
	</div>

	<div class="channel" (click)="navigate('good')">
		<h2>
			<span>F<span>athom</span><f-semantic>:</f-semantic></span>
			<span>for good</span>
		</h2>
		<p>
			<span class="desktop"
				>Our fundraising communications help you help the world.</span
			>
			<f-stylistic class="mobile">Nonprofits love us.</f-stylistic>
		</p>
		<a class="go" href="/good" (click)="navigate('good')"
			><span>Go</span></a
		>
	</div>

	<div class="channel" (click)="navigate('agencies')">
		<h2>
			<span>F<span>athom</span><f-semantic>:</f-semantic></span>
			<span>for agencies</span>
		</h2>
		<p>
			<span class="desktop">We’re your silent partner for branding, identity, brochures, web sites, videos, and more.</span>
			<f-stylistic class="mobile">Let's team up.</f-stylistic>
		</p>
		<a class="go" href="/agencies" (click)="navigate('agencies')"
			><span>Go</span></a
		>
	</div>
	<!-- TODO: find a way to increase DRYness here so that the blocker elements block hover, but not clicks -->
	<div class="blocker" (click)="navigate('business')"></div>
	<div class="blocker" (click)="navigate('business')"></div>
	<div class="blocker" (click)="navigate('good')"></div>
	<div class="blocker" (click)="navigate('good')"></div>
	<div class="blocker" (click)="navigate('agencies')"></div>
	<div class="blocker" (click)="navigate('agencies')"></div>
</div>

<f-footer></f-footer>
