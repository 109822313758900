import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { IndexPage } from "./pages/index.component";
import { GoodPage } from "./pages/good/component";
import { BusinessPage } from "./pages/business/component";
import { AgenciesPage } from "./pages/agencies/component";
import { _404Page } from "./pages/404.component";

const routes: Routes = [
	{
		path: "agencies",
		children: [{ path: "", component: AgenciesPage, children: [] }]
	},
	{
		path: "business",
		children: [{ path: "", component: BusinessPage, children: [] }]
	},
	{
		path: "good",
		children: [{ path: "", component: GoodPage, children: [] }]
	},
	{ path: "", component: IndexPage, children: [] },
	{ path: "**", component: _404Page, children: [] }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: "enabled"
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
