<f-microsite site="agencies" color="#019cd7"
	video="https://player.vimeo.com/external/393502654.m3u8?s=cfe14c60a6fde226cf1bacbfee1d3ebecffdd55a"
	videoPoster="/assets/images/video/agencies-poster.jpg">
	<ng-container headline>We don't mind being<f-br></f-br> unsung heroes.</ng-container>
	<ng-container asterisk>Behind every great agency or consulting firm are great
		partnerships—with firms like Fathom. You've probably never heard of us.
		And that's the point.</ng-container>

	<ng-container about>
		Fathom offers a fresh take and expansive capabilities, making your agency look
		extra awesome.
	</ng-container>

	<ng-container about-identity>
		We can produce additional options to supplement your in-house staff. From hand
		illustration to animation, we bring a broad skillset to bear and can produce
		powerful identity systems that get noticed.
	</ng-container>

	<ng-container about-design>
		Fathom is rooted in design—print design, interactive pdfs, presentations,
		infographics, advertising. We can also help with rapid-turn digital printing or even
		hand-printed material on our 1886 letterpress!
	</ng-container>

	<ng-container about-development>
		From a targeted microsite to a full-fledged web presence using Wordpress or
		custom coding, Fathom is here to support you with excellent web development
		services.
	</ng-container>

	<ng-container about-video>
		Our crew can bring video into the mix for advertising, an explainer video for your
		client’s website, custom animation, and more.
	</ng-container>
</f-microsite>
