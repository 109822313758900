import { Component, OnInit } from "@angular/core";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { Platform } from "src/app/platform.service";
import { ViewportScroller } from "@angular/common";
import { AnimationBuilder } from "@angular/animations";
import { Router } from "@angular/router";

@Component({
	templateUrl: "./component.html"
})
export class BusinessPage implements OnInit {
	constructor(
		private title: Title,
		private meta: Meta,
		public platform: Platform,
		public sanitizer: DomSanitizer,
		public viewportScroller: ViewportScroller,
		public animationBuilder: AnimationBuilder,
		public router: Router
	) {}

	ngOnInit(): void {
		this.title.setTitle(
			"Identity, design, development, and video | Fathom for business"
		);
		this.meta.updateTag({
			property: "og:title",
			content:
				"Identity, design, development, and video | Fathom for business"
		});
		this.meta.updateTag({
			name: "description",
			content:
				"Fathom creates tailored solutions for every client. Need identity? A custom website that sets you apart? A short documentary that tells your story? Sure thing...let's do it!"
		});
		this.meta.updateTag({
			property: "og:description",
			content:
				"Fathom creates tailored solutions for every client. Need identity? A custom website that sets you apart? A short documentary that tells your story? Sure thing...let's do it!"
		});
		this.meta.updateTag({
			property: "og:image",
			content: "/assets/images/ogp/business.png"
		});
	}
}
