import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class Platform {
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		this.IS_BROWSER = isPlatformBrowser(this.platformId);
	}

	public readonly IS_BROWSER: boolean;
}
