import { Component } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Platform } from "./platform.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  host: {
  }
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private platform: Platform
  ) {
    this.route.fragment.subscribe(fragment => {
      if (fragment != null && fragment != "") {
        this.viewportScroller.scrollToAnchor(fragment);
      }
    });
  }
}
